$padding-content: 16vw;

.home-page{
  .home-banner{
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: calc(100vh - #{$nav-height-responsive} - #{$footer-height-responsive});
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @media #{$small-and-up} {
      background-size: 70%;
      height: calc(100vh - #{$nav-height} - #{$footer-height});
      background-position: bottom center;
      @include flexbox();
      @include flex-direction(row);
      @include align(center);
    }
    @media #{$medium-and-down} {
      height: calc(100vh - #{$nav-height-responsive} - #{$footer-height-responsive});
    }
    .info-container{
      padding: 2rem;
      max-width: 70%;
      @media #{$small-and-up} {
        padding: 1rem;
        max-width: 80%;
        @include flexbox();
        @include flex-direction(column);
        @include align(center);
      }
      .main-title{
        @media #{$small-and-down} {
          font-size: 32px;
          position: relative;
        }
        @media #{$small-and-up} {
          @include flex(1);
          width: 100%;
          @include flexbox();
          @include flex-direction(row);
        }
        span{
          position: relative;
          margin-bottom: 1rem;
          @media #{$small-and-down} {
            @include flexbox();
            @include flex(1);
          }
          &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: calc(100% - #{$padding-content});
            height: 2px;
            background: color('gray', 'lighten-4');
            display: block;
            @media #{$small-and-down} {
              left: 0;
              right: initial;
              width: 90%;
            }
          }
          &:before{
            z-index: 1;
            content: '';
            position: absolute;
            right: $padding-content;
            bottom: 0;
            width: 100px;
            height: 2px;
            background: $color-primary;
            display: block;
            @media #{$small-and-down} {
              left: 0;
              right: initial;
            }
          }
        }
        .title-a{
          @include flex(1);
          padding-right: $padding-content;
          text-align: right;
          @media #{$small-and-down} {
            padding-right: initial;
            padding-left: 0;
          }
        }
        .title-b{
          @include flex(1);
          padding-left: $padding-content;
          @media #{$small-and-down} {
            padding-right: initial;
            padding-left: 0;
          }
          &:after{
            left: inherit;
            right: 0;
            @media #{$small-and-down} {
              left: 0;
              right: initial;
            }
          }
          &:before{
            left: $padding-content;
            @media #{$small-and-down} {
              left: 0;
              right: initial;
            }
          }
        }
      }
      .main-description{
        color: color('gray', 'lighten-1');
        @media #{$small-and-up} {
          @include flexbox();
          @include flex-direction(row);
          width: 100%;
          font-size: 1.05vw;
          line-height: 1.3;
        }
        .description-a{
          @media #{$small-and-up} {
            @include flex(1);
            padding-right: $padding-content;
            text-align: right;
          }
        }
        .description-b{
          @media #{$small-and-up} {
            @include flex(1);
            padding-left: $padding-content;
          }
          &.link-content{
            @media #{$small-and-up} {
              text-align: right;
            }
            @media #{$small-and-down} {
              margin-top: 1.5rem;
              margin-bottom: 3rem;
            }
          }
        }
      }
    }
  }
  .title-block{
    h2{
      @media #{$medium-and-down} {
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -1rem;
          width: calc(100% - 2rem);
          height: 2px;
          background: color('gray', 'lighten-4');
          display: block;
        }
        &:before{
          z-index: 1;
          content: '';
          position: absolute;
          bottom: -1rem;
          width: 100px;
          height: 2px;
          background: $color-primary;
          display: block;
          left: 0;
        }
      }
    }
    @media #{$medium-and-down} {
      &:after{
        display: none;
      }
      &:before{
        display: none;
      }
    }
  }
  .id-reference{
    position: relative;
    top: -133px;
    @media #{$medium-and-down} {
      top: -100px;
    }
  }
}