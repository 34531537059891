$promos-background: color('gray', 'base');
$promos-background-opacity: .75;
$promos-radius: 8px;

@keyframes fadeInPromo {
  from {background: rgba($promos-background, $alpha: 0);}
  to {background: rgba($promos-background, $alpha: $promos-background-opacity);}
}
@keyframes enterTopPromo {
  from {right: -1000px;}
  to {right: 2px;}
}

.promos-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 799;
  width: 100%;
  display: none;
  height: calc(100% - #{$footer-height});
  background: rgba($modal-background, $alpha: 0);
  .promo-container {
    z-index: 2;
    border-radius: $promos-radius;
    width: auto;
    position: absolute;
    bottom: 0;
    opacity: 0;
    background: $modal-container-primary-background;
    max-width: 80%;
    padding: 1rem;
    @include transition(all .8s);
  }
  .close-area{
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &:after{
      content: '';
      display: block;
      width: calc(100% - 101px);
      background: rgba($promos-background, $alpha: $promos-background-opacity);
      animation-name: fadeInPromo;
      animation-duration: .6s;
      position: absolute;
      height: $footer-height;
      bottom: -#{$footer-height};
      left: 0;
    }
  }
  &.promos-active{
    display: block;
    animation-name: fadeInPromo;
    animation-duration: .6s;
    background: rgba($modal-background, $alpha: $modal-background-opacity);
    .promo-container{
      right: 2px;
      opacity: 1;
      animation-name: enterTopPromo;
      animation-duration: .8s;
    }
  }
}
