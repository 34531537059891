.form-modal{
  @media #{$small-and-up} {
    @include flexbox();
    @include flex-direction(row);
  }
  .left-area{
    text-align: center;
    @media #{$small-and-up} {
      flex: .5;
      @include flexbox();
      @include flex-direction(column);
    }
    .description-form{
      text-align: left;
    }
  }
  .contact-form{
    @media #{$small-and-up} {
      flex: 1;
      @include flexbox();
      @include flex-direction(column);
      padding-left: 2rem;
    }
  }
}